import Vue from 'vue'
import VueRouter from 'vue-router'

import Dashboard from '../pages/Dashboard'
import Home from '../pages/Home'
import Avisos from '../pages/Avisos'
import Login from '../pages/auth/Login'
import AlterarSenha from '../pages/auth/AlterarSenha'
import Responsaveis from '../pages/gerenciarCadastros/Responsaveis'
import Assuntos from '../pages/gerenciarCadastros/Assuntos'
import OrigensDestinos from '../pages/gerenciarCadastros/OrigensDestinos'
import Documentos from '../pages/gerenciarCadastros/Documentos'
import Protocolos from '../pages/protocolos/Protocolos'




Vue.use(VueRouter)


const routes = [
    { name: 'home', path: '/home', component: Home },
    { name: 'dashboard', path: '/dashboard', component: Dashboard },
    { name: 'avisos', path: '/avisos', component: Avisos },
    { name: 'auth', path: '/login', component: Login },
    { name: 'alterarSenha', path: '/alterar-senha', component: AlterarSenha },
    { name: 'responsaveis', path: '/responsaveis', component: Responsaveis },
    { name: 'assuntos', path: '/assuntos', component: Assuntos },
    { name: 'origensDestinos', path: '/origens-destinos', component: OrigensDestinos },
    { name: 'documentos', path: '/documentos', component: Documentos },
    { name: 'protocolos', path: '/protocolos', component: Protocolos },
    
]





const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})


export default router