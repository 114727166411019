<template>
  <div>


    <div class="text-center">
      <b-spinner variant="warning" label="Spinning"></b-spinner>
      <b-spinner variant="warning" type="grow" label="Spinning"></b-spinner>
      <b-spinner variant="danger" label="Spinning"></b-spinner>
      <b-spinner variant="danger" type="grow" label="Spinning"></b-spinner>
      <b-spinner variant="info" label="Spinning"></b-spinner>
      <b-spinner variant="info" type="grow" label="Spinning"></b-spinner>
      <!-- <b-spinner variant="primary" label="Spinning"></b-spinner>
      <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner> -->
      <b-spinner variant="success" label="Spinning"></b-spinner>
      <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
    </div>
  

    <div class="tabelaProdutos" v-show="false">
        <b-table id="tabelaProdutos" striped hover :items="dados.produtos" :fields="fields"></b-table>
    </div>



  </div>
</template>

<script>
import {jsPDF} from 'jspdf'
import 'jspdf-autotable'
import "jspdf-barcode";
import dayjs from 'dayjs';

export default {
  name: "ImprimirProtocolo",
  props: {
    dados: {}
  },
  

  data() {
    return {

      load: false,
      dataHoje: null,
      data: null,
      hora: null,


    }
  },


  async created() {

    this.usuario = JSON.parse(localStorage.getItem('user'))
    
    this.data = dayjs(this.dados.dataCreate).format('DD/MM/YYYY')
    this.hora = dayjs(this.dados.dataCreate).format('HH:mm')


    await new Promise(r => setTimeout(r, 1000))

    // console.log('dados', this.dados)
    this.gerarPdf()
  },

  

  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
      })
    },
    

    getImgUrl(nomeFile) {
        var images = require.context('../../assets/', false, /\.png$/)
        return images('./' + nomeFile + ".png")
    },


    async gerarPdf() {

      
      var doc = jsPDF('p', 'mm', [210, 297]);
      // var doc = jsPDF('l', 'mm', [210, 297]);

  

      // Topo
      //------------------------------------------------------
      var img = new Image()
      img.src = this.getImgUrl('logoSind') 
      doc.addImage(img, 'png', 9, 6, 27, 25) 

      const width = doc.internal.pageSize.getWidth()
      // const height = doc.internal.pageSize.getHeight()


      doc.setDrawColor(0);
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(10, 45, 190, 27, 3, 3, 'FD');


      doc.setFontSize(24).setFont(undefined, 'bold'); 
      doc.text('FOLHA', width/2, 15, { align: 'center' })
      doc.text('DESPACHOS', width/2, 26, { align: 'center' })


      doc.setFontSize(18).setFont(undefined, 'bold'); 
      doc.text('Protocolo: '+this.dados.cod, width/2, 41, { align: 'center' })

      // doc.rect(10, 35, 190, 28); BOM
      //------------------------------------------------------



      
      doc.setFontSize(9).setFont(undefined, 'bold'); 
      
      // Cabeçalho
      //------------------------------------------------------
      doc.text("Nº DOCUMENTO", 13, 52, { align: 'left' })
      doc.text(this.dados.numeroDocumento, 13, 56, { align: 'left' })

      doc.text("ASSUNTO", 13, 64, { align: 'left' })
      doc.text(this.dados.assunto, 13, 68, { align: 'left' })



      doc.text("ORIGEM", 90, 52, { align: 'left' })
      doc.text(this.dados.origensDestinos[0].origem, 90, 56, { align: 'left' })

      doc.text("DESTINO", 90, 64, { align: 'left' })
      doc.text(this.dados.origensDestinos[0].destino, 90, 68, { align: 'left' })



      doc.text("DATA", 181, 54, { align: 'left' })
      doc.text(this.data, 181, 58, { align: 'left' })
      doc.text(this.hora, 181, 62, { align: 'left' })
      //------------------------------------------------------




      // Corpo
      //---------------------------------
      doc.setDrawColor(0);
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(10, 74, 190, 139, 3, 3, 'FD');

      // doc.rect(10, 63, 190, 150);
      doc.setLineWidth(0.3);
      doc.line(105, 213, 105, 74); 
      //---------------------------------


      let traco = '-'
      for (let i=0; i < 139; i++) {
        traco += '-'
      }

      doc.setFontSize(11).setFont(undefined, 'bold'); 
      doc.text(traco, 15, 218, { align: 'left' })



      // doc.rect(10, 220, 190, 68);

      // RECIBO
      //--------------------------------------------------------------
      doc.setDrawColor(0);
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(10, 220, 190, 68, 3, 3, 'FD');


      doc.setFontSize(24).setFont(undefined, 'bold'); 
      doc.text('RECIBO', width/2, 230, { align: 'center' })


      doc.setFontSize(11).setFont(undefined, 'bold'); 
      doc.text("PROTOCOLO", width/2, 237, { align: 'center' })
      doc.text(this.dados.cod, width/2, 241, { align: 'center' })


      doc.text("Nº DOCTO.", 15, 239, { align: 'left' })
      doc.text(this.dados.numeroDocumento, 15, 243, { align: 'left' })

      doc.text("ASSUNTO", 15, 250, { align: 'left' })
      doc.text(this.dados.assunto, 15, 254, { align: 'left' })


      doc.text("ORIGEM", 196, 240, { align: 'right' })
      doc.text(this.dados.origensDestinos[0].origem, 196, 244, { align: 'right' })

      doc.text("DESTINO", 196, 250, { align: 'right' })
      doc.text(this.dados.origensDestinos[0].destino, 196, 254, { align: 'right' })

      doc.setFontSize(9).setFont(undefined, 'bold'); 
      doc.text("DATA", width/2, 270, { align: 'center' })
      doc.text(this.data, width/2, 274, { align: 'center' })
      doc.text(this.hora, width/2, 278, { align: 'center' })


      doc.setFontSize(11).setFont(undefined, 'bold'); 

      doc.text("        ADRIANA REGINA MAGIONE", 15, 270, { align: 'left' })
      doc.text("__________________________________", 15, 272, { align: 'left' })
      doc.text("                  GERADOR POR ", 15, 278, { align: 'left' })


      doc.text("__________________________________", 124, 272, { align: 'left' })
      doc.text("                  RECEBIDO POR ", 124, 278, { align: 'left' })
      
      //--------------------------------------------------------------




      doc.barcode(this.dados.cod.toString(), {
        fontSize: 43,
        textColor: "#000000",
        x: 157.4,
        y: 28.5
      })

      doc.barcode(this.dados.cod.toString(), {
        fontSize: 30,
        textColor: "#000000",
        x: 167.4,
        y: 233.0
      })


      doc.save(`Protocolo_${this.dados.cod}.pdf`)
    },


  }



}

</script>

<style scoped>


.cursor {
  cursor: pointer;
}

.button-aplicar {
  margin-top: 32px;
}

.button-imprimir {
  margin-bottom: 25px;
  margin-right: 20px;
  height: 40px;
  width: 210px;
  border: 0;
  border-radius: 50px;
  background: #8f23d6;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}



@media (max-width: 700px) {


  .tabela {
    position: relative;
    margin-left: 15px;
    width: 90vw;
    overflow: hidden;
  }



}

</style>