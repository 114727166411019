<template>
    <div id="home">

        <!-- <h1>Oi</h1>
        <button @click="saveFaturamento">Salvar</button>
        <button @click="clearFaturamentos">Limpar</button>

        <ul>
            <li v-for="item in faturamentos" :key="item.id">
                {{item.text}}
            </li>
        </ul> -->


        <div class="logo">
          <img src="../assets/logoSind.png" alt="" />
        </div>


    </div>
</template>

<script>
export default {

    created() {

        this.clearFaturamentos()
        // this.$store.dispatch('faturamento/getFaturamento')
        // this.$store.commit("auth/toggleMenu", true);
    },

    computed: {
        faturamentos() {
            // return this.$store.state.faturamento.items
            // return this.$store.getters['faturamento/allFaturamentos'](myParam)
            return this.$store.getters['faturamento/allFaturamentos']
        }
    },

    methods: {

        saveFaturamento() {
            const dados = { id: 5, text: 'Conecitos NodeJS', done: true }
            this.$store.dispatch('faturamento/saveFaturamento', dados) 
        },

        clearFaturamentos() {
            this.$store.dispatch('faturamento/getFaturamento') 
        }

    }

}
</script>

<style scoped>



button {
  margin-top: 10px;
  height: 40px;
  border: 0;
  border-radius: 50px;
  background: #ec8619;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}



img {
  /* height: 45px; */
  margin-top: -15px;
  margin-right: 7px;
}


</style>






