<template>
  <div id="page-login">
    
    <aside>
      <div class="fundo-aside"></div>
    </aside>

    <main>
      <div class="loginArea" v-if="login">
        <div class="logo">
          <img src="../../assets/logo.png" alt="" />
          <span id="logo-texto1">Protocolo </span>
          <!-- <span id="logo-texto2">de Férias</span> -->
        </div>
        <!-- <span>Bem-vindo de volta!</span> -->
        <form @submit.prevent="signIn">
          <input type="text" placeholder="seu e-mail" v-model="email" />
          <input type="password" placeholder="sua senha" v-model="senha" />
          <button type="submit" :class="{disabled: validating}">{{textLogin}}</button>
        </form>
        <a>Esqueci minha senha</a>
        <!-- <a @click="toggleTelaLogin">Esqueci minha senha</a> -->
      </div>

      <div class="loginArea" v-else>
        <h1>Enviar senha</h1>
        <form @submit.prevent="handreRegister">
          <input type="text" placeholder="email@emai.com" v-model="email" />
          <button @click="esqueciMinhaSenha">Enviar e-mail</button>
        </form>
        <a @click="toggleTelaLogin">Lembrei da senha</a>
      </div>
    </main>
  </div>
</template>




<script>
import api from "../../services/api";

export default {
  name: "Login",
  data() {
    return {
      nome: "",
      email: "",
      senha: "",
      validating: false,
      login: true,
      textLogin: 'Acessar'
    };
  },
  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },

    toggleTelaLogin() {
        // this.$store.commit("toggleMenu", false);
        this.$store.commit('auth/toggleMenu', false)

        this.login = !this.login;
        this.nome = "";
        this.email = "";
        this.password = "";
    },

    async signIn() {

        // this.$store.commit("auth/setLoading", true);
        this.textLogin = 'Autenticando...'
        this.validating = true

        const user = {
            email: this.email,
            senha: this.senha,
        };



        api.post("auth/signin", user)
        .then((res) => {


          localStorage.setItem("user", JSON.stringify(res.data));
          this.$store.commit("auth/setUser", res.data);
          // this.$store.commit("setColonia", res.data);
          this.$store.commit("setEmpresas", res.data.empresas);

          localStorage.setItem('path', '')
          this.$store.commit("auth/toggleMenu", true);
          this.$store.commit("auth/setLoading", false);

          
          this.$router.push({ name: 'protocolos' });

        })
        .catch((error) => {
          // console.log("eerororor", error)
          this.$store.commit("auth/setLoading", false);
          this.textLogin = 'Acessar'  
          this.validating = false

          if (!error.response) {
            this.msgNotification('Falha ao tentar conectar com o servidor.', 'error', 5000)
            return
          }
          

          error.response.status == 400
          ? this.msgNotification(error.response.data.msg, 'warning', 3000)
          : this.msgNotification(error.response.data, 'error', 3000)

          return;
        }); 

    },


    esqueciMinhaSenha() {

      if (!this.email) {
        this.msgNotification('INFORME O E-MAIL', 'error', 3000)
        return
      }

      api.put("signforgot", { email: this.email })
      .then(() => {
        // console.log(res.data);
        this.msgNotification('NOVA SENHA ENVIADA!', 'success', 5000)
        // this.$router.replace({ name: "dashboard" });
      })
      .catch(err => this.msgNotification(err.response.data, 'error', 3000))

    }



  },

};
</script>




<style scoped>
#page-login {
  /* background: #7159c1; */
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 100vh;
}

aside {
  /* background: #17a2b8; */
  background: url("../../assets/a5.jpg");
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: cover;
  height: 100vh;
}

.fundo-aside {
  height: 100%;
  width: 100%;
  background: #ec8619;
  opacity: 0.8;
}

main {
  /* background: cyan; */
  height: 100vh;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.tela {
  height: 300px;
  width: 300px;
  background: darkgoldenrod;
}

.loginArea {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 100px;
  max-width: 600px;
  width: 420px;
  height: 330px;
}

form {
  display: flex;
  flex-direction: column;
  margin: 0px 25px;
}

input {
  margin-bottom: 15px;
  height: 40px;
  width: 300px;
  font-size: 18px;
  padding: 10px;
  outline: none;
  border: 0;
  border-radius: 9px;
  background: rgb(241, 241, 241);
}

button {
  margin-top: 10px;
  height: 40px;
  border: 0;
  border-radius: 50px;
  background: #ec8619;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}

.loginArea a {
  margin-top: 10px;
  text-decoration: none;
  color: #ec8619;
  font-size: 15px;
  cursor: pointer;
}

img {
  height: 45px;
  margin-top: -15px;
  margin-right: 7px;
}

#logo-texto1 {
  font-size: 32px;
  font-weight: bold;
  margin-top: 55px;
}

.logo {
  margin-bottom: auto;
  margin-top: auto;
}

#logo-texto2 {
  font-size: 32px;
  font-weight: 500;
}

</style>
