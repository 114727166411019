
import dayjs from 'dayjs'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';
import api from '../../services/api'
import ImprimirProtocolo from './ImprimirProtocolo'

// import Vue from 'vue';

export default {
  name: "Protocolo",
  components: { 
    DatePicker,
    ImprimirProtocolo
  },

  data() {
    return {

      componentKey: 0,
      textStatusDB: '',
      busca: null,
      dataFiltro:[],
      modalIsShow: false,
      teclasDigitadas: '',

      listas: {
        documentos: [],
        assuntos: [],
        responsaveis: [],
        origensDestinos: []
      },

      form: {
        id: null,
        idDocumento: null,
        documento: null,
        numeroDocumento: null,
        idAssunto: null,
        assunto: null,
        origensDestinos: []
      },

      formOrigemDestino: {
        id: null,
        idProtocolo: null,
        idOrigem: null,
        origem: null,
        idDestino: null,
        destino: null,
        idResponsavel: null,
        sedex: null,
        responsavel: null,
        comentarios: null
      },

      load: false,

      items: [],

      fieldsProtocolos: [
        { key: 'cod', label: 'Código' },
        { key: 'documento', label: 'Documento' , sortable: true},
        { key: 'numeroDocumento', label: 'Número documento' , sortable: true},
        { key: 'assunto', label: 'Assunto' , sortable: true},
        { key: 'userCreate', label: 'Usuário' , sortable: true},
        { key: "dataCreate", label: "Data", sortable: true, formatter: value => { 
          return dayjs(value).format('DD/MM/YYYY HH:mm')
        }},
        { key: 'Imprimir', label: 'Imprimir', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'Destinos', label: 'Destinos', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'Editar', label: 'Editar', thClass: 'text-right', tdClass: 'text-right' },
        { key: 'Apagar', label: 'Apagar', thClass: 'text-center', tdClass: 'text-center' }
      ],

      fieldsOrigensDestinos: [
        { key: 'origem', label: 'Origem' },
        { key: 'destino', label: 'Destino' },
        { key: 'sedex', label: 'Sedex/Registrado' },
        { key: 'responsavel', label: 'Responsável' },
        { key: 'userCreate', label: 'Usuário' },
        { key: "dataCreate", label: "Data Criação", formatter: value => { 
          return dayjs(value).format('DD/MM/YYYY HH:mm')
        }},
        { key: 'Comentarios', label: 'Comentarios', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'Editar', label: 'Editar', thClass: 'text-right', tdClass: 'text-right' },
        { key: 'Apagar', label: 'Apagar', thClass: 'text-center', tdClass: 'text-center' }
      ],


    }
  },

  created() {

    let dataInicio = dayjs().startOf('month')
    dataInicio = new Date(dataInicio)

    this.dataFiltro[0] = dataInicio
    this.dataFiltro[1] = new Date()

    this.getListas()
    this.getProtocolos()
  },

  methods: {


    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },

    forceRerender() {
      this.componentKey += 1;  
    },

    changeCampoBusca(evento) {
      // console.log(evento.keyCode)

      if (this.busca == '' || this.busca == null) {
        // console.log('this.busca == ')
        this.getProtocolos()
        return 
      }
      
      if (evento.keyCode == 13 && this.busca != '') {
        // console.log('evento.keyCode == 13')
        this.buscarProtocolo()
        // this.busca == ''
        return 
      }
    },

    getListas() {
  
        api.get(`protocolos/listas`)
        .then((res) => {

          this.listas = res.data
        })
        .catch((err) => {

          this.load = false;
          if (err.response.data.messages) {
            err.response.data.messages.forEach(message => this.msgNotification(message, 'warning', 5000))
          } 
          else {
            this.msgNotification(err.response.data, 'warning', 5000)
          }
        });
    },


    async getProtocolos() {


      this.load = true

      const dataIn = dayjs(this.dataFiltro[0]).format('YYYY-MM-DD')
      const dataOut = dayjs(this.dataFiltro[1]).format('YYYY-MM-DD')
      // console.log(dataIn, dataOut)

      // api.get(`protocolos?periodo=${this.filtro.periodo}`)
      api.get(`protocolos/${dataIn}/${dataOut}`)
      .then((res) => {
        
        this.load = false
        this.items = res.data
        
      })
      .catch((err) => {
        this.load = false
        if (err.response.data.messages) {
          err.response.data.messages.forEach(message => this.msgNotification(message, 'warning', 5000))
        } 
        else {
          this.msgNotification(err.response.data, 'warning', 5000)
        }
      });
      
    },

    async buscarProtocolo() {

      // if (!this.busca)
      //   this.getProtocolos()


      if (!this.busca) {
        this.getProtocolos()
        return
      }



      this.load = true

      api.get(`protocolos/buscar/pesquisa/filtro?filtro=${this.busca}`)
      // api.get(`protocolos/buscar/pesquisa/${this.busca}`)
      .then((res) => {
        
        this.load = false
        this.items = res.data
        
      })
      .catch((err) => {
        this.load = false
        if (err.response.data.messages) {
          err.response.data.messages.forEach(message => this.msgNotification(message, 'warning', 5000))
        } 
        else {
          this.msgNotification(err.response.data, 'warning', 5000)
        }
      });
      
    },


    async getTextDocumento(id) {
      this.form.documento = this.listas.documentos.filter(item => item.value == id)[0].text

      this.form.numeroDocumento = ' '
      await new Promise(r => setTimeout(r, 100))
      this.form.numeroDocumento = ''
    },

    getTextAssunto(id) {
      this.form.assunto = this.listas.assuntos.filter(item => item.value == id)[0].text
    },

    getTextResponsaveis(id) {
      this.formOrigemDestino.responsavel = this.listas.responsaveis.filter(item => item.value == id)[0].text
    },

    getTextOrigem(id) {
      this.formOrigemDestino.origem = this.listas.origensDestinos.filter(item => item.value == id)[0].text
    },

    getTextDestino(id) {
      this.formOrigemDestino.destino = this.listas.origensDestinos.filter(item => item.value == id)[0].text
    },


    showModalComentarios(destino) {

      this.formOrigemDestino = {...destino}

      this.$refs["modal-comentarios"].show()
    },


    showModalAdd() {

      // Usado para saber se o modal está aberto.
      this.modalIsShow = true

      this.resetForm()
      this.textStatusDB = 'Cadastrar'
      this.$refs["modal-add"].show()
    },

    validar() {

      if (this.form.id) {
        this.update()
      }
      else {

        api.post("protocolos/validar", this.form)
        .then(async () => {
  
          this.$refs["modal-add"].hide()
          await new Promise(r => setTimeout(r, 200))
          this.$refs["modal-add-destino"].show()
  
        })
        .catch((err) => {
          if (err.response.data.messages) {
            err.response.data.messages.forEach(message => this.msgNotification(message, 'warning', 5000))
          } 
          else {
            this.msgNotification(err.response.data, 'warning', 5000)
          }
        });
      }


    },

    cadastrar() {
      // alert('oi')
      this.form.id ? this.addOuEditaOrigemDestino() : this.save()
    },

    cancelar() {

      // Usado para saber se o modal está aberto.
      this.modalIsShow = false

      this.$refs["modal-add-destino"].hide()
      this.resetForm()
    },

    save() {

      this.form.origensDestinos = []
      this.form.origensDestinos.push(this.formOrigemDestino)
  
      api.post("protocolos", this.form)
      .then(res => {

        this.modalIsShow = false
        this.$refs["modal-add-destino"].hide()
        this.msgNotification(res.data, 'success', 5000)
        this.getProtocolos()
        this.resetForm()

      })
      .catch((err) => {
        // this.load = false;
        if (err.response.data.messages) {
          err.response.data.messages.forEach(message => this.msgNotification(message, 'warning', 5000))
        } 
        else {
          this.msgNotification(err.response.data, 'warning', 5000)
        }
      });

    },

    update() {

      api.put("protocolos", this.form)
      .then(res => {

        this.modalIsShow = false
        this.$refs["modal-add"].hide()
        this.msgNotification(res.data, 'success', 5000)
        this.getProtocolos()
        this.resetForm()

      })
      .catch((err) => {
        // this.load = false;
        if (err.response.data.messages) {
          err.response.data.messages.forEach(message => this.msgNotification(message, 'warning', 5000))
        } 
        else {
          this.msgNotification(err.response.data, 'warning', 5000)
        }
      });

    },

    editar(protocolo) {
      // console.log(protocolo)
      this.modalIsShow = true
      this.form = {...protocolo}
      this.textStatusDB = 'Editar'
      this.$refs["modal-add"].show()
    },

    showModalApagar(item) {
      // acessoRestrito(33)
      this.$refs["modal-del"].show()
      this.form = item
    },

    apagar() {

      this.$refs["modal-del"].hide()

      api.delete(`protocolos/${this.form.id}`)
      .then(() => {
        this.msgNotification('Protocolo removido.', 'success', 5000)
        this.resetForm()
        this.getProtocolos()
      })
      .catch((err) => {
        // this.load = false;
        if (err.response.data.messages) {
          err.response.data.messages.forEach(message => this.msgNotification(message, 'warning', 5000))
        } 
        else {
          this.msgNotification(err.response.data, 'warning', 5000)
        }
      });
        
    },

    resetForm() {
  
      const form = this.form
      for (var proper in form){

        if (proper == 'origensDestinos') {
          form[proper] = []
        }
        else
          form[proper] = null
      }

      this.resetFormOrigensDestinos()
    },



    showModalAddDestino(protocolo) {
      // console.log('protocolo',protocolo)
      this.modalIsShow = true
      this.form = {...protocolo}
      this.resetFormOrigensDestinos()
      this.textStatusDB = 'Cadastrar'
      this.$refs["modal-add-destino"].show()

      // Pega o ultimo destino e coloca como nova origem
      const ultIndex = protocolo.origensDestinos.length - 1
      this.formOrigemDestino.idOrigem = protocolo.origensDestinos[ultIndex].idDestino
      this.formOrigemDestino.origem = protocolo.origensDestinos[ultIndex].destino
    },

    editarDestino(protocolo, destino) {
      // console.log('destino', destino, evento)
      this.modalIsShow = true
      this.form = {...protocolo}
      this.formOrigemDestino = {...destino}
      this.textStatusDB = 'Editar'
      this.$refs["modal-add-destino"].show()
    },

    addOuEditaOrigemDestino() {

      // console.log('this.formOrigemDestino.id', this.formOrigemDestino.id)
      // console.log('this.formOrigemDestino', this.formOrigemDestino)

      if (this.formOrigemDestino.id) {

        const form = {
          ...this.formOrigemDestino
        }
  
        api.put("protocolos/origem-destino", form)
        .then(res => {
  
          this.modalIsShow = false
          this.$refs["modal-add-destino"].hide()
          this.msgNotification('Destino atualizado.', 'success', 5000)

          // Substitui destino na table
          for (let protocolo of this.items) {
            var index = protocolo.origensDestinos.findIndex(i => i.id == res.data.id)
            if (index !== -1) {
              protocolo.origensDestinos[index] = res.data
            }
          }

          this.forceRerender()
          this.resetForm()
          this.resetFormOrigensDestinos()
  
        })
        .catch((err) => {
          // this.load = false;
          if (err.response.data.messages) {
            err.response.data.messages.forEach(message => this.msgNotification(message, 'warning', 5000))
          } 
          else {
            this.msgNotification(err.response.data, 'warning', 5000)
          }
        });

      } 
      else {

        // console.log('this.form.id', this.form.id)
        // console.log('this.formOrigemDestino', this.formOrigemDestino)
        
        const form = {
          ...this.formOrigemDestino,
          idProtocolo: this.form.id
        }
  
        api.post("protocolos/origem-destino", form)
        .then(res => {

          // Atualiza visualização do btn editar/apagar em destinos
          //-----------------------------------------------------
          res.data.btnEditarApagarShow = true
          this.form.origensDestinos.forEach((item, index) => {
            if (index+1 == this.form.origensDestinos.length) {
              item.btnEditarApagarShow = false
            }
          })
          this.form.origensDestinos.push(res.data)
          //-----------------------------------------------------
  
          this.modalIsShow = false
          this.$refs["modal-add-destino"].hide()
          this.msgNotification('Novo destino cadastrado.', 'success', 5000)
          this.resetForm()
          this.resetFormOrigensDestinos()
  
        })
        .catch((err) => {
          // this.load = false;
          if (err.response.data.messages) {
            err.response.data.messages.forEach(message => this.msgNotification(message, 'warning', 5000))
          } 
          else {
            this.msgNotification(err.response.data, 'warning', 5000)
          }
        });
      }


    },

    showModalApagarDestino(item) {
      // acessoRestrito(33)
      this.$refs["modal-del-destino"].show()
      this.formOrigemDestino = item
    },

    apagarDestino() {

      this.$refs["modal-del-destino"].hide()

      api.delete(`protocolos/origem-destino/${this.formOrigemDestino.id}`)
      .then(() => {

        this.$refs["modal-add-destino"].hide()
        this.msgNotification('Destino removido.', 'success', 5000)

        // Apaga destino
        for (let protocolo of this.items) {
          var index = protocolo.origensDestinos.findIndex(i => i.id == this.formOrigemDestino.id)
          if (index !== -1) {
            protocolo.origensDestinos.splice(index,1)
            var idProtocolo = protocolo.id
          }
        }

        // Atualiza visualização do btn editar/apagar em destinos
        //-----------------------------------------------------
        for (let protocolo of this.items) {
          if (protocolo.id == idProtocolo) {
            const ultIndex = protocolo.origensDestinos.length
            protocolo.origensDestinos[ultIndex - 1].btnEditarApagarShow = true
          }
        }
        //-----------------------------------------------------
          

        // this.forceRerender()
        this.resetForm()
        this.resetFormOrigensDestinos()
        
        
      })
      .catch((err) => {
        // this.load = false;
        if (err.response.data.messages) {
          err.response.data.messages.forEach(message => this.msgNotification(message, 'warning', 5000))
        } 
        else {
          this.msgNotification(err.response.data, 'warning', 5000)
        }
      });
        
    },



    resetFormOrigensDestinos() {

      const form2 = this.formOrigemDestino
      for (var proper2 in form2) {
          form2[proper2] = null
      }
    },


    async imprimirProtocolo(item) {

      // console.log(item)
      this.form = item

      this.$refs["modal-aguarde"].show()
      await new Promise(r => setTimeout(r, 1000))
      this.$refs["modal-aguarde"].hide()
    },

    verificaNumeroOficio() {

      const regex = /\//
      const contemBarra = regex.test(this.form.numeroDocumento)

      // console.log(contemBarra, e.charCode, e.keyCode)

      // code 47 igual /


      if (contemBarra) {

        // Valida se ano depois da barra("/") tem 4 digitos.
        //------------------------------------------------------
        const cods = this.form.numeroDocumento.split('/')
        if (cods[1].length >= 4) 
        return 'return event.charCode == null'
        //------------------------------------------------------

        return 'return event.charCode == 46 || event.charCode >= 48 && event.charCode <= 57'
      }
      else {

        // Não permite zeros a esquerda.
        //--------------------------------------------------
        if (this.form.numeroDocumento.length == 0) {
          return 'return event.charCode == 46 || event.charCode == 47 || event.charCode >= 49 && event.charCode <= 57'
        } 
        // Coloca barra("/") caso já tenho 6 números.
        //--------------------------------------------------
        else
        if (this.form.numeroDocumento.length == 6) {
          this.form.numeroDocumento += "/" 
          return 'return event.charCode == null'
        } 
        //--------------------------------------------------

        return 'return event.charCode == 46 || event.charCode == 47 || event.charCode >= 48 && event.charCode <= 57'
      } 

    }


    // iniciaTimerTeclas() {
            
    //   const timer = setInterval(() => {

    //     this.teclasDigitadas = '' 

    //     clearInterval(timer)

    //   }, 1000)

    // },


  },

  mounted() {


    const campoNumDocumento = document.querySelector("#campoNumDocumento")

    campoNumDocumento.addEventListener("paste", e => {
      // console.log("ee",e)
      e.preventDefault()
    })

    // window.addEventListener("keyup", e => {
    //   // console.log(String.fromCharCode(e.keyCode));
    //   console.log(e, this.busca);
    //   if (this.modalIsShow) return
    //   if (this.busca == '') {
    //     this.getProtocolos()
    //     return
    //   }


    //   const teclaPres = String.fromCharCode(e.keyCode)

    //   // if (this.teclasDigitadas.length == 0) {
    //   //   this.buscarReservas()
    //   //   return 
    //   // }

    //   if(teclaPres.match(/[0-9]+/)) {
    //     this.teclasDigitadas += teclaPres
    //     this.iniciaTimerTeclas()
    //   }

      
    //   if (e.keyCode == 13) {
    //     this.$refs.focusBuscar.focus()
    //     this.busca = this.teclasDigitadas
    //     this.buscarProtocolo()
    //     this.teclasDigitadas = ''
    //     this.busca = ''
    //   }
      
    //   // console.log('this.teclasDigitadas',this.teclasDigitadas);
    // });

  }

}