<template>
  <div>
    <b-navbar toggleable="lg" type="dark"  class="topo">
        
        <img src="../assets/logo.png" alt="Dashboard" @click="toDashboard">
        <b-navbar-brand class="nome-empresa" href="#" @click="toDashboard">Protocolo </b-navbar-brand>
        <!-- <b-navbar-brand class="nome-empresa" to="/dashboard" href="#">Finances Plus</b-navbar-brand> -->
        <!-- <router-link to="/dashboard" exact class="nome-empresa">EventoSP</router-link> -->

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>      


            <b-nav-item-dropdown text="Gerenciar Cadastros" class="menu active item-drop" right>
                <b-dropdown-item @click="toDocumentos()">
                    <b-icon icon="clipboard-check" aria-hidden="true"></b-icon>
                    Documentos
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>

                <b-dropdown-item @click="toAssuntos()">
                    <b-icon icon="x-diamond" aria-hidden="true"></b-icon>
                    Assuntos
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>

                <b-dropdown-item @click="toResponsaveis()">
                    <b-icon icon="person" aria-hidden="true"></b-icon>
                    Responsáveis
                </b-dropdown-item>
                
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="toOrigensDestinos()">
                    <b-icon icon="arrow-left-right" aria-hidden="true"></b-icon>
                    Origens e Destinos
                </b-dropdown-item>
            </b-nav-item-dropdown>



            <b-button-group>
                <button class="button" @click="toProtocolos()">Protocolo</button>
            </b-button-group>

        </b-navbar-nav>



        


        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">

            <!-- <div class="notificacoes" @click="toExibirAvisos">
                <div class="balao-notificacao">
                    <b-badge class="quantia-notificacao" variant="info" v-if="countAvisos">{{countAvisos}}</b-badge>
                    <b-icon icon="bell-fill" class="icone" variant="none"></b-icon>
                </div>
            </div> -->

            <b-nav-item-dropdown class="active" right>
                <!-- Using 'button-content' slot -->
                <template #button-content>
                <em>{{userName}}</em>
                </template>
                <!-- <b-dropdown-item @click="gerenciarUsuarios" v-if="menusAdminsShow">Gerenciar Usuários</b-dropdown-item>
                <b-dropdown-item @click="alterarSenha">Alterar Senha</b-dropdown-item> -->
                <b-dropdown-item href="#" @click="signOut">Sair</b-dropdown-item>
            </b-nav-item-dropdown>

        </b-navbar-nav>

      </b-collapse>
    </b-navbar>



  </div>
</template>

<script>
import api from "@/services/api";
// import { acessoRestrito } from "@/global";
export default {
    data(){
        return{

            menusAdminsShow: true,
            userName: '',
            menuVisivel: false,


            
            countAvisos: 0,
            avisos: []

        }
    },

    created() {  

        // this.menusAdminsShow = acessoRestrito(1000, false)
        this.getAvisos()
    },

    methods: {

        getUserNome() {
            const authLocal = localStorage.getItem('user')
            const auth = JSON.parse(authLocal)
            this.userName = auth.nome
        },

        signOut() {
            // console.log('swdwde')
            localStorage.removeItem('user')
            localStorage.setItem('path', '/neutro')

            this.$store.commit('auth/toggleMenu', false)
            this.$store.commit('auth/setUser', null)
            this.$store.commit("setEmpresas", []);
            this.$store.commit("setEmpresa", null);

            this.$router.push({path: '/login'})
        },


        


        async toDashboard() {


            this.$router.push({ name: 'home' })
            await new Promise(r => setTimeout(r, 50));
            this.$router.push({ name: 'dashboard' })

            // if (this.sedes.length > 1) {
            //     this.$router.push({ name: 'home' })
            //     await new Promise(r => setTimeout(r, 50));
            //     this.$router.push({ name: 'dashboardContas' })
            // } else {
            //     this.$router.push({ name: 'home' })
            //     await new Promise(r => setTimeout(r, 50));
            //     this.$router.push({ name: 'dashboard' })
            // }
        },

        getAvisos() {
            api.get(`avisos`)
            .then((res) => {
                
                this.avisos = res.data
                localStorage.setItem('avisos', JSON.stringify(res.data))

                this.countAvisos = this.avisos.filter(item => item.classCSSCorCard == 'card-nao-lido' ).length
            })
            .catch((err) => { this.msgNotification(err.response.data, 'error', 5000) })   
        },



        toHome() {
            this.$router.push({ name: 'home' })
        },

        toResponsaveis() {
            this.$router.push({ name: 'responsaveis' })
        },

        toAssuntos() {
            this.$router.push({ name: 'assuntos' })
        },

        toOrigensDestinos() {
            this.$router.push({ name: 'origensDestinos' })
        },

        toDocumentos() {
            this.$router.push({ name: 'documentos' })
        },

        toProtocolos() {
            this.$router.push({ name: 'protocolos' })
        },

        toExibirAvisos() {
            this.countAvisos = 0
            this.$router.push({ name: 'avisos' })
        },


        alterarSenha() {
            this.$router.push({ name: 'alterarSenha' })   
        },

        gerenciarUsuarios() {
            this.$router.push({ name: 'gerenciarUsuarios' }) 
        },



    },

    mounted() {
        this.getUserNome()  
    }


};
</script>

<style scoped>

    img {
        height: 40px;
        margin-right: 7px;
        cursor: pointer;
    }

    .topo {
        background: #fb8700;
    }

    /* .nome-empresa {
        font-weight: bold;
        margin-right: 50px;
        cursor: pointer;
    } */

    .nome-empresa {
        font-weight: bold;
        font-size: 20px;
        color: white;
        /* padding-top: 10px; */
        /* margin-top: 10px; */
        margin-right: 15px;
        cursor: pointer;
        text-decoration: none;
    }

    .item-drop {
        margin-right: 20px;
        /* font-size: 23; */
    }

    .nav-link-sel {
        font-weight: bold;
    }

    .menu {
        margin-top: 1px;
        margin-left: 10px;
        margin-right: 20px;
        font-weight: bold;
        font-size: 17px;
    }

    .notificacoes {
        margin-right: 50px;
        height: 40px;
        width: auto;
        display: flex;
        gap: 20px;
        cursor: pointer;
        /* background-color: aqua;    */
    }

    .balao-notificacao {
        display: flex;
        display: -webkit-flex; 
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: 40px;
        width: 40px;
        background-color: #fda138;
        border-radius: 50px;
    }

    .icone {
        color: white;
        height: 22px;
        width: 22px;
    }

    .quantia-notificacao {
        position: absolute;   
        margin-bottom: 30px; 
        margin-left: 30px; 
    }

    button {
        margin-top: 0px;
        height: 40px;
        width: 170px;
        border: 3px solid rgb(252, 252, 255);
        border-radius: 50px;
        background: #f59e1d;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        outline: none;
        cursor: pointer;
    }

</style>