<template>
    <div id="page">
      <b-list-group>
        <div>
          <h2>Assuntos</h2>
  
            <b-row align-h="between">
                
                <button class="button-cadastrar" @click="showModalAdd">Cadastrar</button>
                <!-- <b-button class="btnRight" variant="btn btn-info btn-sg" @click="showModalAdd" >Cadastrar</b-button> -->
  
                <div class="form-group col-lg-4">
                    <label><span class="text-danger"></span> Buscar</label>
                    <input class="form-control" v-model="filtro" />
                </div>
  
            </b-row>
        </div>
  
  
        <div class="div-corpo">
  
          <div class="tabela">
            <b-table
              id="my-table"
              striped
              hover
              class="text-left"
              ref="selectableTable"
              :items="filtrar"
              :fields="fields"
              :busy="load"
              small
              outlined
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong></strong>
                </div>
              </template>
  
  
              <template #cell(Editar)="data">
                <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
                <img
                  src="../../assets/edit1.png"
                  alt="editar"
                  height="23px"
                  @click="editar(data.item)"
                />
              </template>
              <template #cell(Apagar)="data">
                <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
                <img
                  src="../../assets/del4.png"
                  alt="apagar"
                  height="23px"
                  @click="showModalApagar(data.item)"
                />
              </template>
  
  
            </b-table>
          </div>
        
  
        </div>
      </b-list-group>
  
  
  
  
      <!-- MODAL CADASTRO -->
      <div>
        <b-modal ref="modal-add" hide-footer :title="textStatusDB" size="mx">
          <div class="row text-left col-lg-12">
  
            <div class="form-group col-lg-12">
              <label><span class="text-danger">*</span> Nome</label>
              <b-form-input
                class="text-uppercase"
                v-model="form.nome"
                maxlength="80"
              ></b-form-input>
            </div>

            <div class="form-group col-lg-12">
              <b-col sm="12">
                <b-form-group label-cols="12" label-cols-lg="12" label="Comentários">
                  <div>
                      <b-form-textarea 
                      v-model="form.descricao"
                      rows="3"
                      ></b-form-textarea>
                  </div>           
                </b-form-group>   
              </b-col>
            </div>
  
          </div>
  
          <b-container class="bv-example-row">
            <!-- <b-row align-h="start">
              <b-button class="mt-3 botao-confirmar2" variant="success" @click="save">Confirmar</b-button>
              <b-button class="mt-3 botao-confirmar2" variant="danger" @click="$refs['modal-add'].hide()">Cancelar</b-button>
            </b-row> -->
  
            <b-row align-h="around">
              <b-button class="mt-3 botao-confirmar" variant="success" @click="cadastrar">Confirmar</b-button>
              <b-button class="mt-3 botao-confirmar" variant="danger" @click="$refs['modal-add'].hide()">Cancelar</b-button>
            </b-row>
          </b-container>
  
        </b-modal>
      </div>
      <!-- MODAL CADASTRO FIM -->
  
  
  
  
      <!-- MODAL DELETAR -->
      <div>
        <b-modal ref="modal-del" hide-footer title="">
          <div class="d-block text-center">
            <h3>Deseja remover?</h3>
            <h4>{{ form.nome?.toLowerCase() }}</h4>
          </div>

          <b-row align-h="around">
            <b-button class="mt-3 botao-confirmar" variant="success" @click="apagar">Sim</b-button>
            <b-button class="mt-3 botao-confirmar" variant="danger" @click="$refs['modal-del'].hide()">Não</b-button>
          </b-row>
          
        </b-modal>
      </div>
      <!-- MODAL DELETAR FIM-->
  
  
  
    </div>
  </template>
  
  <script>
  // import dayjs from 'dayjs'
  import api from "../../services/api";
  // import { acessoRestrito } from "@/global";
  
  export default {
    name: "Assuntos",
  
    data() {
      return {

        filtro: '',
  
        textStatusDB: '',
  
        form: {
          id: null,
          nome: null
        },
  
        fields: [
          { key: "id", label: "Código" },
          { key: "nome", label: "Nome" },
          { key: "Editar", label: "Editar", thClass: 'text-right', tdClass: 'text-right' },
          { key: "Apagar", label: "Apagar", thClass: 'text-center', tdClass: 'text-center' },
        ],
  
        items: [], 
  
        load: false,
  
      };
    },
  
  
  
    created() {
      // this.colonia = this.$store.state.colonia
      this.getLista();
    },
  
    methods: {
  
  
      msgNotification(texto, tipo, tempo) {
        this.$toast.open({
          message: texto,
          type: tipo,
          position: 'top-right',
          duration: tempo
          // all of other options may go here
        })
      }, 
      
  
  
      getLista() {
        // acessoRestrito(30)
  
        this.load = true;
  
        api.get(`assuntos`)
        .then((res) => {
          this.load = false
          this.items = res.data
        })
        .catch((err) => {

          this.load = false;
          if (err.response.data.messages) {
            err.response.data.messages.forEach(message => this.msgNotification(message, 'warning', 5000))
          } 
          else {
            this.msgNotification(err.response.data, 'warning', 5000)
          }

        });
  
      },   
  
  
      showModalAdd() {
        // acessoRestrito(31)
        this.resetForm()
        this.textStatusDB = 'Cadastrar'
        this.$refs["modal-add"].show()
      },

      cadastrar() {
        this.form.id ? this.update() : this.save()
      },
  
      save() {
  
        api.post("assuntos", this.form)
        .then(res => {
  
          this.$refs["modal-add"].hide()
          this.msgNotification(res.data, 'success', 5000)
          this.getLista()
          this.resetForm()
  
        })
        .catch((err) => {
          // this.load = false;
          if (err.response.data.messages) {
            err.response.data.messages.forEach(message => this.msgNotification(message, 'warning', 5000))
          } 
          else {
            this.msgNotification(err.response.data, 'warning', 5000)
          }
        });
  
      },

      update() {
  
        api.put("assuntos", this.form)
        .then(res => {
  
          this.$refs["modal-add"].hide()
          this.msgNotification(res.data, 'success', 5000)
          this.getLista()
          this.resetForm()
  
        })
        .catch((err) => {
          // this.load = false;
          if (err.response.data.messages) {
            err.response.data.messages.forEach(message => this.msgNotification(message, 'warning', 5000))
          } 
          else {
            this.msgNotification(err.response.data, 'warning', 5000)
          }
        });
  
      },
  
      editar(item) {
        // acessoRestrito(32)
        this.textStatusDB = 'Editando'
        this.form = {...item}
        this.$refs["modal-add"].show()
      },
      
  
      showModalApagar(item) {
        // acessoRestrito(33)
        this.$refs["modal-del"].show()
        this.form = item
      },
  
      apagar() {

        this.$refs["modal-del"].hide()
  
        api.delete(`assuntos/${this.form.id}`)
        .then(() => {
          this.msgNotification('Assunto removido.', 'success', 5000)
          this.getLista()
        })
        .catch((err) => {
          // this.load = false;
          if (err.response.data.messages) {
            err.response.data.messages.forEach(message => this.msgNotification(message, 'warning', 5000))
          } 
          else {
            this.msgNotification(err.response.data, 'warning', 5000)
          }
        });
          
      },
  
  
  
  
      resetForm() {
  
        const form = this.form
        
        for (var proper in form){
          form[proper] = null
        }
        
      },
  
  
  
    },
  
    computed: {
  
        filtrar() {
  
          if (this.filtro.trim() == '') return this.items
              
          let listaFiltrada = []
          this.items.forEach((item) => {
            if(item.id.toString().match(this.filtro)) listaFiltrada.push(item)
            if(item.nome.toLowerCase().match(this.filtro)) listaFiltrada.push(item)
          })

          return listaFiltrada
          // return this.items
        },
    },
  
    
  };
  </script>
  
  <style scoped>
  /* #id {
          margin: 30px;
      } */
  
  
  .div-corpo{
    height: calc(100vh - 260px);
    overflow: scroll;
    cursor: pointer;
  }
  
  .button-cadastrar {
    margin-top: 30px;
    margin-left: 15px;
    height: 40px;
    width: 190px;
    border: 0;
    border-radius: 50px;
    background: #fb8700;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    outline: none;
    cursor: pointer;
  }
  
  .botao-confirmar {
    width: 45%; 
    /* width: 170px;  */
    margin-left: 10px;
  }
  
  .botao-confirmar2 {
    width: 170px; 
    margin-left: 10px;
  }
  
  #page h2 {
    margin-top: 50px;
    margin-bottom: 15px;
    color: #fb8700;
  }
  
  h4 {
    margin-bottom: 35px;
    color: #f44825;
  }

  
  /* .btnRight {
    height: 40px;
    width: 140px;
    margin-top: 33px;
    margin-bottom: 10px;
    margin-left: 15px;
    font-weight: bold;
  } */
  
  .tabela {
    width: 80vw;
  }
  
  img {
    cursor: pointer;
  }
  
  span {
    margin-top: 30px;
  }
  
  table.b-table[aria-busy="true"] {
    opacity: 0.6;
  }
  
  @media (max-width: 700px) {
  
      h2 {
          margin-left: 15px;
          font-size: 22px;
          font-weight: bold;
      }
  
  
      .tabela {
          margin-left: 15px;
          width: 310px;
          overflow: auto;
      }
  
      .btnRight {
          height: 40px;
          width: 90%;
          margin-top: 33px;
          margin-left: 15px;
          font-weight: bold;
      }
  
      
  
  }
  </style>